.footer{
    display: flex;
    background-color: rgb(99, 177, 229);
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2.5rem;
    color: rgba(255, 255, 255, 1.0);
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    text-align: center;
    line-height: 30.0px;
    flex-basis: 160px;
    align-items: center;
    justify-content: center;
}