html {
    box-sizing: border-box;
    min-height: 100%;
  }
  
  body {
    font-family: "Helvetica", Helvetica, Arial, serif;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  h2 {
    text-align: center;
  }
  
  .page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .page .heading {
    height: 120px;
    background-color: #00338D;
  }
  
  .heading .logo {
    width: 300px;
    padding-top: 28px;
    align-items: center;
    justify-content: center;
  }
  
  .page .main-content {
    flex: 1;
  }
  
// .carousel-item{
//     float: none !important;
// }

  .main-content .content-detail-logo {
    margin: 38px 0 115px 0;
    background-color: rgba(240, 240, 240, 1.0);

  }
  
  .content-detail-logo img {
    padding: 50px;
  }
  
  .page .main-content .context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .main-content .context .section {
    font-size: 26.0px;
    line-height: 20.0px;
    text-align: center;
    width: 41%;
  }
  
  .main-content .context .section-2 {
    padding: 32px 0 58px 0;
  }
  
  .highlight-name {
    color: rgb(99, 177, 229);
    font-weight: 600;
  }
  
  .page .footer {
    display: flex;
    background-color: rgb(99, 177, 229);
    color: rgba(255, 255, 255, 1.0);
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    text-align: center;
    line-height: 30.0px;
    font-size: 22.0px;
    flex-basis: 160px;
    align-items: center;
    justify-content: center;
  }
  
  @media only screen and (max-width: 460px) {
    .main-content .context .section {
      width: 81%;
    }
  
    .main-content .content-detail-logo {
      margin: 0 0 68px 0;
      padding: 0;
    }
  
    .content-detail-logo img {
      padding: 3%;
      width:100%;
    }
  
    .page .footer {
      line-height: 24.0px;
      font-size: 16.0px;
    }
  
    .page{
      width:100%;
    }
  
    .page .heading img{
      width:80%
    }
  
    .content-circle-logo img{
      width:50%;
    }

    .henrico-circle-logo{
      padding-top: 2.5rem !important;
    }
  
    body{
      width:100%;
    }
  
  
  }